import './Section.css'

function Section({ children, style, id }) {
    return(
        <section id={id} style={style}>
            {children}
        </section>
    );
}

export default Section