import './Gallery.css'
import { motion } from "framer-motion";
import {useEffect, useState} from "react";

function Gallery () {
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() =>{
        const handleScroll = () => {
            const wrappers = document.querySelectorAll('.wrapper-gallery');

            if (wrappers.length === 0) return;

            wrappers.forEach((wrapper) => {
                const img = wrapper.querySelector('img');

                if (img) {
                    const wrapperTop = wrapper.getBoundingClientRect().top;
                    const windowHeight = window.innerHeight;

                    if (wrapperTop < windowHeight && wrapperTop + wrapper.clientHeight > 0) {
                        const percentVisible = (windowHeight - wrapperTop) / (windowHeight + wrapper.clientHeight);

                        const maxOffset = wrapper.clientHeight - img.clientHeight; // Limiter à la hauteur de l'image
                        const offset = Math.min(Math.max(maxOffset * percentVisible, maxOffset), 0);

                        img.style.transform = `translateY(${offset}px)`;
                    }
                }
            });
        };


        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseImage = () => {
        setSelectedImage(null);
    };
    return(
        <>
            <div className="container">
                {[...Array(8)].map((_, index) => (
                    <div className="wrapper-gallery" key={index}
                         onClick={() => handleImageClick(`/img/gallery/${index + 1}.png`)}>
                        <img src={`/img/gallery/${index + 1}.png`} alt=""/>
                    </div>
                ))}
            </div>
            {selectedImage && (
                <motion.div
                    className="overlay"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    onClick={handleCloseImage}
                >
                    <motion.img
                        src={selectedImage}
                        initial={{scale: 0.5}}
                        animate={{scale: 1}}
                        transition={{duration: 0.5}}
                        className="large-image"
                    />
                </motion.div>
            )}
        </>
    );
}

export default Gallery;