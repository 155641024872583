const en = {
    entete:[{
        bouton1: 'Projects',
        bouton2: 'About me',
        bouton3: 'Contact',
        bouton4: 'Follow me'
    }],
    accueil: [{
        titre: 'Welcome on my page!',
        texte: 'Here, you can find information about me and my various projects as a young aerospace student and a self-taught programmer.'
    }],
    connaissance: [{
        titre: 'My skills',
        card1: [{
            title: "Design",
            text: "Being very interested in visual arts, I create innovative designs tailored to various needs. My skills cover visual identity design, logo creation, and user interface design (UI/UX). I mainly use tools like Adobe Photoshop, Illustrator, and Figma to carry out projects ranging from brand creation to illustration design.",
            underligned: ['My skills cover visual identity design, logo creation, and user interface design (UI/UX).'],
        }],
        card2: [{
            title: "Programming",
            text: "As a self-taught developer, I design and develop various web or other applications using different languages such as PHP, React, Python, C++, and basic web development. My projects include creating dynamic websites, automation scripts, and scientific projects. Each project is designed to be performant, while allowing me the opportunity to constantly learn more.",
            underligned: ['using different languages such as PHP, React, Python, C++, and basic web development.'],
        }],
        card3: [{
            title: "Engineering",
            text: "Driven by a passion for space since I was young, I am currently studying aerospace engineering at IPSA. Through my studies, I develop essential engineering skills, particularly in physics. My goal is to actively contribute to space exploration and the advancement of technologies that will shape the future of the space industry.",
            underligned: ['I am currently studying aerospace engineering at IPSA.'],
        }]
    }],
    projet: [{
        titre: "Experiences & Projects",
        experiences: [
            {
                id: 1,
                image: 'img/thtrading.png',
                titre: 'THTrading - Website',
                texte: 'THTrading.fr is a sleek and modern platform designed to offer users a seamless experience in trading-related content. Built using php and vanilla JavaScript, the website features a clean, user-friendly interface with responsive design elements. Its minimalist layout ensures fast load times and smooth navigation across all devices. THTrading.fr features an online store with a subscription system, offering users exclusive access to online course, ensuring a tailored and engaging experience for subscribers. ',
                insta: 'https://www.instagram.com/p/C-p5TQnCWx3/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                site: 'https://thtrading.fr/',
                date: 'Completed in August 2024',
            },
            {
                id: 2,
                image: 'img/mingreaud.png',
                titre: 'Manoir le Mingreaud - Website / Branding',
                texte: 'I developed mingreaud.fr, the official website for Manoir le Mingreaud, a venue offering accommodations and event hosting. Built with a focus on usability and aesthetics, the site features seamless navigation and responsive design, showcasing the manor’s services for weddings, seminars, and family stays. The project involved custom HTML, CSS, and JavaScript.',
                insta: 'https://www.instagram.com/p/C-AjMi0C_C4/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                site: 'https://mingreaud.fr/',
                date: 'Completed in June 2024',
            },
            {
                id: 3,
                image: 'img/gameengine.png',
                titre: 'Graphics Engine - OpenGL C++',
                texte: 'Since I was young, I have always been fascinated by the idea of creating in 3D, and even more by the world of video games. This is why I recently decided to embark on the development of a graphics and physics engine. This project allows me to explore how to create one, understand how it works, and maybe even build my own. Unfortunately, I have little time to work on it, but I try to make progress as much as I can.',
                insta: '',
                site: 'https://github.com/Le-Mouton/OpenGL-Project',
                date: 'Started in February 2024',
            },
            {
                id: 4,
                image: 'img/motiondetection.png',
                titre: 'Motion Detection - Python',
                texte: 'As part of my engineering degree, we have to take several exams, including projects like this one. This project was probably one of the most interesting in my opinion because we had to design a script that detects the presence of motion in a series of images using several mathematical methods, including the square points method.',
                insta: '',
                site: 'https://github.com/Le-Mouton/MOTION-DETECTION',
                date: 'Completed in April 2024',
            },
            {
                id: 5,
                image: 'img/aco.png',
                titre: 'ACielOuvert - Website (Back-end)',
                texte: 'ACielOuvert is a collaborative student project at my school, the aim of which is to popularize and publish aeronautical culture on a website. The aim of this project was to recreate the website in php in order to leave the Wix web host. I recreated all the site\'s functionalities: connection system with user administration, article publication, tag system, etc. The site isn\'t finished yet, awaiting the work of other students.',
                insta: 'https://www.instagram.com/a_ciel_ouvert_ipsa?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
                date: 'Completed in February 2023',
            },
            {
                id: 6,
                image: 'img/astrogp.jpg',
                titre: 'ASTROGP - Website',
                texte: 'I developed astrogp.celescope.fr, a website dedicated to the IPSA Toulouse participatory astronomy project. The site provides an overview of various student-led astronomy projects, including data processing and observational studies. Built using HTML, CSS, and vanilla JavaScript, the website offers easy navigation and real-time project updates. This project allowed me to combine technical web development skills with a passion for science, creating a platform that showcases both educational and scientific content.',
                insta: 'https://www.instagram.com/astronomie_participative?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
                site: 'https://astrogp.celescope.fr/index.html',
                date: 'Completed in February 2023',
            }
        ]
    }],
    apropos: [{
        titre: 'About Me',
        texte: [
            {
                part1: 'My name is Mathys Herbreteau, I am a young student passionate about space, and I use my curiosity and desire to learn to create and discover new worlds. With a unique blend of programming and engineering.'
            },
            {
                part2: 'I am currently studying at IPSA, an aerospace engineering school, where I have the opportunity to pursue an engineering degree. In parallel with my studies, I am self-learning to program in several languages, including Python, C++, PHP, React, and basic web development. This dual pathway allows me to develop a solid set of theoretical and practical skills.'
            },
            {
                part3: 'Outside of work, I am passionate about cycling and running, which I regularly practice. I also enjoy working with my hands, which has led me to take up welding and woodworking. As a result, I have acquired varied knowledge in many different fields.'
            },
            {
                part4: 'Feel free to browse the entire site to discover some of my projects and learn more about my background and achievements. I am always open to new collaborations and opportunities. If you find my profile interesting, do not hesitate to contact me.'
            }
        ]
    }],
    contact: [{
        titre: 'Contact',
        texte: [
            {
                part1: 'You can find all my programming work on GitHub. I also stream on Twitch and publish videos on YouTube when I have the time.'
            },
            {
                part2: 'If you want to contact me, feel free to send me an email or a direct message on any social media platform!'
            }
            ]
    }],
    footer: [{
        texte: 'Designed and created by Mout.'
    }],
    reste:[
        {
            bouton:[
                {
                    decouvrir: 'Discover it',
                    visite: 'Visit it',
                    cosmos: 'More'
                }],
            alert: 'Email copied to clipboard!',
        }
    ]
};

export default en;