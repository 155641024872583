import React, { useState, useEffect } from 'react';
import './CustomCursor.css';

function CustomCursor() {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        const handleMouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setImageIndex((prevIndex) => (prevIndex + 1) % 3);
        }, 300);

        return () => clearInterval(intervalId);
    }, []);

    const images = [
        '/img/cursor-pointer.svg',
        '/img/cursor-pointer-left.svg',
        '/img/cursor-pointer-right.svg'
    ];

    return (
        <div
            className="custom-cursor"
            style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
                backgroundImage: `url(${images[imageIndex]})`,
            }}
        ></div>
    );
}

export default CustomCursor;
