import './CustomAlert.css';
import Button from "./Button";

const CustomAlert = ({ message, onClose }) => {
    return (
        <div className="custom-alert-overlay">
            <div className="custom-alert">
                <p>{message}</p>
                <Button variant={'fond'} onClick={onClose}>Ok</Button>
            </div>
        </div>
    );
};

export default CustomAlert;