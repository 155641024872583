import './Selector.css'
import {useEffect, useRef, useState} from "react";

function Selector({ titre, onSelect, isSelected }){

    const [isVisible, setIsVisible] = useState(0);
    const selectorRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) =>{
                    if(entry.isIntersecting){
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            { threshold: 0 }
        );

        if(selectorRef.current){
            observer.observe(selectorRef.current);
        }
        return () => {
            if (selectorRef.current) {
                observer.unobserve(selectorRef.current)
            }
        };
    }, []);


    return(
        <button ref={selectorRef}
                className={`selector-button ${isSelected ? 'highlighted-button' : ''}`}
                style={{
                    backfaceVisibility: 'hidden',
                    transform: `translate(${isVisible ? '0' : '50%'}) rotateY(${isVisible ? '0deg' : '180deg'})`,
                }}
                onClick={() => onSelect(titre)}><h4><span className={`${isSelected ? 'highlighted-text-button' : ''}`}>{titre}</span></h4></button>
    );
}
export default Selector