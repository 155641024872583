const fr = {
    entete:[{
        bouton1: 'Projets',
        bouton2: 'À propos',
        bouton3: 'Contact',
        bouton4: 'S\'abonner'
    }],
    accueil: [{
        titre: 'Bienvenue sur ma page!',
        texte: "Ici, vous trouverez des informations sur moi et mes divers projets en tant qu'étudiant en aérospatiale et programmeur autodidacte."
    }],
    connaissance: [{
        titre: 'Mes compétences',
        card1: [{
            title: "Design",
            text: "Étant très intéressé par les arts visuels, je crée des designs innovants adaptés à divers besoins. Mes compétences couvrent la conception d'identités visuelles, la création de logos et le design d'interfaces utilisateurs (UI/UX). J'utilise principalement des outils tels qu'Adobe Photoshop, Illustrator et Figma pour réaliser des projets allant de la conception de marques à la création d'illustrations.",
            underligned: ['conception d\'identités visuelles, la création de logos et le design d\'interfaces utilisateurs (UI/UX).'],
        }],
        card2: [{
            title:"Programmation",
            text:"En tant que développeur autodidacte, je conçois et développe des différentes applications web ou autre en utilisant différents languages telles que PHP, React, Python, C++ et les bases du web. Mes projets incluent la création de sites web dynamiques, de scripts d'automatisation et de projets scientifiques. Chaque projet est conçu pour être performant, tout en me laissant l'opportunité de toujours plus apprendre.",
            underligned:['en utilisant différents languages telles que PHP, React, Python, C++ et les bases du web.'],
        }],
        card3: [{
            title:"Ingénierie",
            text:"Animé depuis jeune par une passion pour le spatial, je suis actuellement étudiant en ingénierie aéronautique et spatiale à l'IPSA. À travers mes études, je développe des compétences d'ingénierie essentielles, notamment en physique. Mon objectif est de contribuer activement à la conquête spatiale et à l'avancée des technologies qui façonneront l'avenir de l'industrie spatiale.",
            underligned:['je suis actuellement étudiant en ingénierie aéronautique et spatiale à l\'IPSA.'],
        }]
    }],
    projet: [{
        titre: "Expériences & Projets",
        experiences: [
            {
                id: 1,
                image: 'img/thtrading.png',
                titre: 'THTrading - Site Web',
                texte: "THTrading.fr est une plateforme moderne et élégante conçue pour offrir aux utilisateurs une expérience fluide sur des contenus liés au trading. Construit en php et JavaScript vanilla, le site propose une interface intuitive et conviviale avec des éléments de design réactifs. Sa mise en page minimaliste assure des temps de chargement rapides et une navigation fluide sur tous les appareils. THTrading.fr propose une boutique en ligne avec un système d'abonnement, offrant aux utilisateurs un accès exclusif à des cours en ligne, garantissant une expérience engageante et personnalisée pour les abonnés.",
                insta: 'https://www.instagram.com/p/C-p5TQnCWx3/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                site: 'https://thtrading.fr/',
                date: 'Terminé en août 2024',
            },
            {
                id: 2,
                image: 'img/mingreaud.png',
                titre: 'Manoir le Mingreaud - Site Web / Branding',
                texte: 'J\'ai développé mingreaud.fr, le site officiel du Manoir le Mingreaud, un lieu offrant des hébergements et l\'organisation d\'événements. Conçu avec un accent sur l\'utilisabilité et l\'esthétique, le site offre une navigation fluide et un design réactif, mettant en valeur les services du manoir pour les mariages, séminaires et séjours familiaux. Le projet impliquait un code HTML, CSS et JavaScript personnalisé.',
                insta: 'https://www.instagram.com/p/C-AjMi0C_C4/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                site: 'https://mingreaud.fr/',
                date: 'Terminé en juin 2024',
            },
            {
                id: 3,
                image: 'img/gameengine.png',
                titre: 'Moteur graphique - OpenGL C++',
                texte: 'Depuis mon plus jeune âge, j\'ai toujours été fasciné par l\'idée de créer en 3D, et encore plus par l\'univers des jeux vidéo. C\'est pourquoi j\'ai récemment décidé de me lancer dans le développement d\'un moteur graphique et physique. Ce projet me permet d\'explorer comment en créer un, de comprendre son fonctionnement, et peut-être même de réaliser le mien. Malheureusement, je dispose de peu de temps pour y travailler, mais j\'essaie d\'avancer du mieux possible.',
                insta: '',
                site: 'https://github.com/Le-Mouton/OpenGL-Project',
                date: 'Commencé depuis février 2024',
            },
            {
                id: 4,
                image: 'img/motiondetection.png',
                titre: 'Détection de mouvement - Python',
                texte: 'Dans le cadre de mon diplôme d\'ingénieur, nous devons réaliser plusieurs examens, notamment des projets comme celui-ci. Ce projet a probablement été l\'un des plus intéressants selon moi, car nous avons dû concevoir un script permettant de détecter la présence de mouvement dans une série d\'images en utilisant plusieurs méthodes mathématiques, dont la méthode des points carrés.' ,
                insta: '',
                site: 'https://github.com/Le-Mouton/MOTION-DETECTION',
                date: 'Terminé en avril 2024',
            },
            {
                id: 5,
                image: 'img/aco.png',
                titre: 'ACielOuvert - Site Web (Back-end)',
                texte: 'ACielOuvert est un projet collaboratif étudiant dans mon école, dont le but est de populariser et publier la culture aéronautique sur un site web. Le but de ce projet était de recréer le site en php afin de quitter l\'hébergeur Wix. J\'ai recréé toutes les fonctionnalités du site : système de connexion avec administration utilisateur, publication d\'articles, système de tags, etc. Le site n\'est pas encore terminé, en attente du travail d\'autres étudiants.',
                insta: 'https://www.instagram.com/a_ciel_ouvert_ipsa?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
                date: 'Terminé en février 2023',
            },
            {
                id: 6,
                image: 'img/astrogp.jpg',
                titre: 'ASTROGP - Site Web',
                texte: 'J\'ai développé astrogp.celescope.fr, un site dédié au projet d\'astronomie participative de l\'IPSA Toulouse. Le site propose un aperçu de divers projets astronomiques menés par les étudiants, incluant le traitement des données et des études d\'observation. Construit en HTML, CSS et JavaScript vanilla, le site offre une navigation facile et des mises à jour en temps réel des projets. Ce projet m\'a permis de combiner des compétences techniques en développement web avec une passion pour la science, créant une plateforme qui met en valeur à la fois des contenus éducatifs et scientifiques.',
                insta: 'https://www.instagram.com/astronomie_participative?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
                site: 'https://astrogp.celescope.fr/index.html',
                date: 'Terminé en février 2023',
            }
        ]
    }],
    apropos: [{
        titre: 'À propos de moi',
        texte: [
            {
                part1: 'Je m\'appelle Mathys Herbreteau je suis un jeune étudiant passionné par le spatial et je mets à profit ma curiosité et mon envie d\'apprendre pour créer et découvrir de nouveaux univers. Avec un mélange unique entre programmation et ingénierie.'
            },
            {
                part2: 'Je suis actuellement étudiant à l\'IPSA, une école d\'ingénierie aérospatiale, où j\'ai l\'opportunité d\'étudier pour un diplome d\'ingénieur. En parallèle de ma formation, j\'apprend en autodidacte à programmer dans plusieurs langages, notamment Python, C++, PHP, React et les bases du web. Cette double voie me permet de développer un ensemble de compétences solides à la fois théoriques et pratiques.'
            },
            {
                part3: 'En dehors du travail, je suis passionné par le cyclisme et la course à pied que je pratique. J\'aime aussi travailler de mes mains, ce qui me conduit à pratiquer la soudure et la menuiserie. J\'ai donc des connaissances variées dans plein de domaines différents.'
            },
            {
                part4: 'N\'hésitez pas à consulter tout le site pour découvrir certains de mes projets et en savoir plus sur mon parcours et mes réalisations. Je suis toujours ouvert à de nouvelles collaborations et opportunités. Si mon profil vous intéresse, n\'hésitez pas à me contacter.'
            }
        ]
    }],
    contact: [{
        titre: 'Contact',
        texte: [
            {
                part1: 'Vous pouvez trouver tout mon travail de programmation sur GitHub. Je streame également sur Twitch et publie des vidéos sur YouTube quand j\'en ai le temps.'
            },
            {
                part2: 'Si vous souhaitez me contacter, n\'hésitez pas à m\'envoyer un email ou un message sur n\'importe quelle plateforme de réseaux sociaux !'
            }
        ]
    }],
    footer: [{
        texte: 'Conçu et créé par Mout.'
    }],
    reste:[
        {
            bouton:[
                {
                decouvrir: 'En savoir plus',
                visite: 'Visiter',
                cosmos: 'Voir plus'
            }],
            alert: 'E-mail copié dans le presse-papier !',
        }
    ]
};

export default fr;
