import React, { useState, useEffect } from 'react';
import './Header.css';
import Button from './Button';
import barNoir from "../img/bar-noir.svg";

function Header({language}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [hasShadow, setHasShadow] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setHasShadow(true);
        } else {
            setHasShadow(false);
        }
    };

    const handleMenuClick = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <header className={`header ${hasShadow ? 'header-shadow' : ''}`}>
            <h1>This is Mout.</h1>
            <nav>
                <Button locate={'experience-section'} >{language.entete[0].bouton1}</Button>
                <Button locate={'about-section'} >{language.entete[0].bouton2}</Button>
                <Button locate={'contact-section'} variant={'contour'}>{language.entete[0].bouton3}</Button>
                <Button href={'https://www.instagram.com/its_mout/'} variant={'fond'}>{language.entete[0].bouton4}</Button>
            </nav>
            <div className="menu-bar">
                <Button onClick={toggleMenu}><div className="column-tab"><span></span><span></span><span></span></div></Button>
            </div>

            {isMenuOpen && (
                <div className="fullscreen-menu">
                    <button className="close-button" onClick={toggleMenu}><i className="fa-solid fa-arrow-right"></i></button>
                    <img src={barNoir} style={{transform: 'rotate(180deg)'}} alt="" />
                    <nav className="menu-nav">
                        <Button locate={'experience-section'} onClick={handleMenuClick}>{language.entete[0].bouton1}</Button>
                        <Button locate={'about-section'} onClick={handleMenuClick}>{language.entete[0].bouton2}</Button>
                        <Button locate={'contact-section'} onClick={handleMenuClick}>{language.entete[0].bouton3}</Button>
                        <Button href={'https://www.instagram.com/its_mout/'} onClick={handleMenuClick}>{language.entete[0].bouton4}</Button>
                    </nav>
                    <img src={barNoir} alt="" />
                </div>
            )}
        </header>
    );
}

export default Header;
