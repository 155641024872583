import './Cards.css'
import {useEffect, useRef, useState} from "react";

function Cards({ children, title, text, underligned, cardClass }) {

    const highlightedText = underligned.reduce((acc, phrase) => {
        const parts = acc.split(phrase);
        return parts.join(`<span class="highlighted">${phrase}</span>`);
    }, text);

    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        const card = e.currentTarget.getBoundingClientRect();
        const xPos = (e.clientX - card.left) / card.width;
        const yPos = (e.clientY - card.top) / card.height;

        const xShift = (xPos - 0.5) * 30;
        const yShift = (yPos - 0.5) * 30;

        setPosition({ x: xShift, y: yShift });
    };

    const handleMouseLeave = () => {
        setPosition({ x: 0, y: 0 });
    };

    const [isVisible, setIsVisible] = useState(0);
    const cardRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) =>{
                    if(entry.isIntersecting){
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            { threshold: 0.5 }
        );

        if(cardRef.current){
            observer.observe(cardRef.current);
        }
        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current)
            }
        };
    }, []);

    return (
        <div
            ref={cardRef}
            className={`card ${isVisible ? 'flipped' : ''} ${cardClass}`}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{
                transform: `rotateY(${isVisible ? '0deg' : '-180deg'}) translate(${position.x}px, ${position.y}px)`,
                transition: 'transform 0.5s linear',
            }}
            >
            <div className="card-front">
                <h2 className="card-title">{title}</h2>
                <p className="card-text" dangerouslySetInnerHTML={{ __html: highlightedText }}></p>
                <div className="card-content">
                    {children}
                </div>
            </div>
            <div className="card-back">
                <p>This is Mout.</p>
            </div>
        </div>
    );
}

export default Cards