import './Button.css';

function Button({ children, variant = 'default', href, onClick, locate }) {
    const handleClick = () => {
        if (locate) {
            const section = document.getElementById(locate);
            section?.scrollIntoView({
                behavior: 'smooth',
            });
        }

        if (onClick) {
            onClick();
        }
    };

    const className = `button ${variant}`;

    if (href) {
        return (
            <a className={className} href={href} target="_blank" rel="noopener noreferrer">
                <span>{children}</span>
            </a>
        );
    }

    return (
        <button onClick={handleClick} className={className}>
            <span>{children}</span>
        </button>
    );
}

export default Button;
