import './Footer.css'
import arrowScroll from '../img/scroll.svg'
import Button from "./Button";

function Footer({ language }) {
    return (
        <footer>
                <p>{language.footer[0].texte}</p>
            <Button locate={'top-section'}>
                <img src={arrowScroll} alt="scroll-to-the-top"/>
            </Button>
        </footer>
    );
}

export default Footer