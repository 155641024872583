import './Experience.css'
import Button from "./Button";

function Experience({ titre, texte, image, insta, site, date}){
    return(
        <div className="wrapper-color">
            <div className="experience-panel">
                <div className="experience-content">
                    <h2 className="experience-title">{titre}</h2>
                    <p className="experience-description">{texte}</p>
                    <img src={image} alt={titre} className="experience-image" />
                    <div className="experience-links">
                        {
                            insta && (
                            <Button variant={'fond'} href={insta}>Instagram</Button>
                            )
                        }
                        {
                            site && (
                            <Button variant={'contour'} href={site}>Visit it</Button>
                            )
                        }
                    </div>
                    <div className="experience-variable">
                        <p>{date}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Experience